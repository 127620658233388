import React, { useContext } from "react";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import { GlobalDataContext } from "../context/context";

function GetQuote() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Get a Quote">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Get a Quote"
          Subheader="Get a Quote"
          bgimg={`${rpdata?.gallery?.[9]}`}
        />
        <div className="w-full my-20">
          <div
            className="bg-cover bg-center bg-opacity-40 md:w-[70%] w-[90%] mx-auto bg-no-repeat bg-fixed"
            style={{ backgroundImage: `url("${rpdata?.dbPrincipal?.logo}")` }}
          >
            <div className="w-full h-full bg-white bg-opacity-90">
            <h2 className="text-[42px] font-bold text-center">Get In Touch</h2>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default GetQuote;
